"use client"

import type { FunctionComponent } from "react"

import { useTranslation } from "@/modules/i18n/components/useTranslation"

import { Error } from "./Error"

type ErrorWithLazyTitleProps = {
  errorId: string
}

export const ErrorWithLazyTitle: FunctionComponent<ErrorWithLazyTitleProps> = ({ errorId }) => {
  const { t, ready } = useTranslation(["shared_server"], true)

  const title = ready ? t("shared_server.error_pages.internal_error.message") : "Internal server error"

  return <Error title={title} errorId={errorId} />
}
