import type { I18nLocale } from "@/modules/i18n/types"

import { unicodeSpacesToNonBreakingSpace } from "@/modules/format/string/unicodeSpacesToNonBreakingSpace"
import { getLocaleTerritoryCode } from "@/modules/locales/getLocaleTerritoryCode"

export const formatNumber = (count: number, locale: I18nLocale, isCompact = true): string => {
  const numberToFormat = isCompact
    ? Intl.NumberFormat(getLocaleTerritoryCode(locale), { notation: "compact" })
    : Intl.NumberFormat(getLocaleTerritoryCode(locale))
  return unicodeSpacesToNonBreakingSpace(numberToFormat.format(count))
}
