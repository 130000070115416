import type { I18nLocale } from "@/modules/i18n/types"

import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"

type URLPart = "domain" | "tld" | "locale"

/**
 * Extract the base host of a given TLD (remove all sub-domains).
 * Ex:
 * - getBaseHost("essec.dev.jobteaser.net") == ".jobteaser.net"
 * - getBaseHost("foo.bar.dev.jobteaser.net") == ".jobteaser.net"
 * - getBaseHost("localhost:4001") == "localhost"
 *
 * This helper is especially useful when you need to determine the base host of
 * the current request, in order to write a cookie on that domain and all its
 * sub-domains (like all ".dev.jobteaser.net" subdomains).
 *
 * @param fullHost - The full host from which the base host will be extracted (can contain port)
 * @returns - The base host, stripped from subdomains & port (may start by `.`)
 */
const getBaseHost = (fullHost: string): string => {
  const baseHostParts = fullHost.split(":")[0].split(".").slice(-2)
  return baseHostParts.length > 1 ? `.${baseHostParts.join(".")}` : baseHostParts[0]
}

const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url))
  } catch (error) {
    return false
  }
}

const isValidHostname = (hostname: string): boolean => {
  // General content and length of the hostname
  if (!/^[a-zA-Z0-9-.]{1,253}\.?$/.test(hostname)) {
    return false
  }

  // should not end with "."
  if (hostname.endsWith(".")) {
    return false
  }

  return hostname.split(".").every(
    piece =>
      /^([a-zA-Z0-9-]+)$/g.test(piece) && // validate global content
      piece.length < 64 && // validate length
      !piece.startsWith("-") &&
      !piece.endsWith("-")
  )
}

const getDomainFromHostname = (hostname: string): string | undefined => {
  if (!isValidHostname(hostname)) {
    throw new Error(`The provided hostname ${hostname} is not valid`)
  }
  if (/^[0-255]\.[0-255]\.[0-255]\.[0-255]*$/.test(hostname)) {
    // Handle ip
    return hostname
  }
  if (/^[a-zA-Z]*$/.test(hostname)) {
    // Handle simple string with only chars
    return hostname
  }
  const domainRegexp = /^(.*\.)*(?<domain>.*)(\..*)$/
  return hostname.match(domainRegexp)?.groups?.domain
}

const getSubdomainFromHostname = (hostname: string): string[] => hostname.split(".").reverse().slice(2).reverse()

const getMonolithHostFromHostname = (hostname: string): string => {
  const cleanedHostName = hostname.replace(/:[0-9]*/, "")
  const [subDomain] = getSubdomainFromHostname(cleanedHostName)
  let monolithHost = (process.env.NEXT_PUBLIC_MONOLITH_API_BASE_URL || "").replace(/https?:\/\//, "")

  if (subDomain) {
    monolithHost = monolithHost.replace("www", subDomain)
  }
  return monolithHost
}

const getCareerExplorerHostFromHostname = (hostname: string): string => {
  const cleanedHostName = hostname.replace(/:[0-9]*/, "")
  const [subDomain] = getSubdomainFromHostname(cleanedHostName)
  let careerExplorerHost = (process.env.NEXT_PUBLIC_CAREER_EXPLORER_API_BASE_URL || "").replace(/https?:\/\//, "")

  if (subDomain) {
    careerExplorerHost = careerExplorerHost.replace("www", subDomain)
  }
  return careerExplorerHost
}

const getTLDFromHostname = (hostname: string): string | undefined => {
  if (!isValidHostname(hostname)) {
    throw new Error(`The provided hostname ${hostname} is not valid`)
  }
  const tldRegexp = /^.*\.(?<tld>.+)$/
  return hostname.match(tldRegexp)?.groups?.tld
}

const getLocaleFromPathname = (pathname: string): I18nLocale | undefined => {
  if (!pathname) {
    throw new Error(`The provided pathname "${pathname}" is not valid`)
  }

  const locale = pathname.split("/")[1] as I18nLocale
  return !AVAILABLE_LOCALES.includes(locale) ? undefined : locale
}

const getExtractedPartFromUrl = (url: string, part: URLPart): string | undefined => {
  if (!isValidUrl(url)) {
    throw new Error(`The provided url ${url} is not valid`)
  }
  const structuredURL = new URL(url)
  if (structuredURL.hostname === "") {
    throw new Error(`The provided url ${url} has no valid hostname`)
  }
  switch (part) {
    case "domain":
      return getDomainFromHostname(structuredURL.hostname)
    case "tld":
      return getTLDFromHostname(structuredURL.hostname)
    case "locale":
      return getLocaleFromPathname(structuredURL.pathname)
    default:
      throw new Error("Implementation not available yet")
  }
}

const getDomainFromUrl = (url: string): string | undefined => getExtractedPartFromUrl(url, "domain")
const getTLDFromUrl = (url: string): string | undefined => getExtractedPartFromUrl(url, "tld")
const getLocaleFromUrl = (url: string): I18nLocale | undefined =>
  getExtractedPartFromUrl(url, "locale") as I18nLocale | undefined

export {
  getBaseHost,
  getDomainFromHostname,
  getDomainFromUrl,
  getLocaleFromPathname,
  getLocaleFromUrl,
  getMonolithHostFromHostname,
  getCareerExplorerHostFromHostname,
  getSubdomainFromHostname,
  getTLDFromHostname,
  getTLDFromUrl,
  isValidHostname,
}
