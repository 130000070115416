export const FE_COOKIE_DOMAIN = ".dev.jobteaser.net"

// feature built from next-jobteaser repo
export const FE_COOKIE_NAME_NEXT = "FEATURE_NEXT"
// feature built from ui-jobteaser repo
export const FE_COOKIE_NAME_UI = "FEATURE_UI"
// feature built from jobteaser repo
export const FE_COOKIE_NAME_RAILS = "FEATURE"

// i18n debug cookie name
export const I18N_DEBUG_COOKIE_NAME = "I18N_DEBUG_ACTIVATED"

// local storage key to persist the feature env indicator minimization
export const FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY = "minimize_feature_env_indicator"

// monolith feature env durations
export const FE_MONOLITH_DURATION_SHORT = "short"
export const FE_MONOLITH_DURATION_LONG = "long"
