"use client"

import type { AppProps } from "next/app"
import type { FunctionComponent, PropsWithChildren } from "react"

import { createContext, useState } from "react"

import { Phrases } from "@/modules/i18n/phrases"
import { useLocale } from "@/modules/locales/useLocale"

export const TranslationContext = createContext<Phrases | null>(null)

type TranslationProviderProps = PropsWithChildren<{
  pageProps?: AppProps["pageProps"]
}>

export const TranslationProvider: FunctionComponent<TranslationProviderProps> = ({ children, pageProps }) => {
  const [phrases] = useState(() => new Phrases())
  const locale = useLocale()

  if (pageProps && pageProps.translations) {
    phrases.add(locale, pageProps.translations)
  }

  return <TranslationContext.Provider value={phrases}>{children}</TranslationContext.Provider>
}
