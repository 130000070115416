import type { ClientSideLogger, EdgeRuntimeLogger } from "./types"
import type { LogFn, Logger, LoggerOptions } from "pino"

import { getClientPageType } from "@/modules/routing/routingManager/clientRoutingManager"
import { computeActiveFlagsToTrackJsMetadata } from "@/modules/unleash/computeActiveFlagsToTrackJsMetadata"

/**
 * Logger for client-side code (use TrackJS)
 * Important:
 * 1. only send "error" logs to TrackJS
 * 2. "logger.info" are NOT sent to TrackJS unless they are later followed by an error
 * 3. "error" and "info" takes only one parameter, the log message
 */
function getClientSideLogger(): ClientSideLogger {
  return {
    debug: (_obj: unknown, _msg?: string) => {
      return undefined
    },
    error: (obj: unknown, msg?: string) => {
      // Dead code elimination for edge runtime (to avoid build warnings about
      // incompatible EdgeRuntime APIs)
      if (typeof window !== "undefined") {
        if (process.env.NEXT_PUBLIC_ENV === "local") {
          if (typeof msg !== "undefined") {
            throw new Error("Logging anything else than a string is not allowed in a client environment.")
          }

          console.error(obj)
        }

        import("@/modules/monitoring/TrackJs/init").then(mod => {
          const { init } = mod
          let activeFlags

          // eslint-disable-next-line no-underscore-dangle
          if (`__APP_DATA__` in window && window.__APP_DATA__ && window.__APP_DATA__.activeFlags) {
            // eslint-disable-next-line no-underscore-dangle, prefer-destructuring
            activeFlags = window.__APP_DATA__.activeFlags
          }

          // eslint-disable-next-line no-underscore-dangle
          if (`__NEXT_DATA__` in window && window.__NEXT_DATA__.props?.pageProps?.serverContext?.activeFlags) {
            // eslint-disable-next-line no-underscore-dangle
            activeFlags = window.__NEXT_DATA__.props?.pageProps?.serverContext?.activeFlags
          }

          init({
            feature: getClientPageType() || "not_specified",
            ...computeActiveFlagsToTrackJsMetadata(activeFlags || []),
          }).then(TrackJs => {
            if (!obj) {
              TrackJs.track("Unable to find error message.")
              return
            }

            const errorMessage = obj.toString()
            // For App router errors, we extract the digest from "obj" (obj = Error)
            const digestData = typeof obj === "object" && "digest" in obj ? ` - error-id="${obj.digest}"` : ""

            TrackJs.track(`${errorMessage}${digestData}`)
          })
        })
      }
    },
    info: (obj: unknown, msg?: string) => {
      if (typeof msg !== "undefined" && process.env.NEXT_PUBLIC_ENV === "local") {
        throw new Error("Logging anything else than a string is not allowed in a client environment.")
      }

      // eslint-disable-next-line no-console
      console.info(obj)
    },
    warn: (obj: unknown, msg?: string) => {
      if (typeof msg !== "undefined" && process.env.NEXT_PUBLIC_ENV === "local") {
        throw new Error("Logging anything else than a string is not allowed in a client environment.")
      }

      // eslint-disable-next-line no-console
      console.warn(obj)
    },
  }
}

/**
 * Logger for NextJS Middleware(s)
 * Important:
 * 1. "error" and "info" takes only one parameter, the log message
 */
function getEdgeRuntimeLogger(): EdgeRuntimeLogger {
  const { pid } = process
  // Locally, Use console.log for all edge runtime logging method to allow pretty-print to
  // "capture" and prettify all logs from `stdout`.
  // eslint-disable-next-line no-console
  const localLogMethod = process.env.NEXT_PUBLIC_ENV === "local" ? console.log : null
  const getLogArgs = (obj: unknown, msg?: string): [unknown, string] => {
    let logMsg
    let logObj
    if (msg) {
      logMsg = msg
      logObj = obj
    } else if (typeof obj === "string") {
      logMsg = obj
      logObj = null
    } else if (obj) {
      logMsg = obj.toString()
      logObj = null
    } else {
      logMsg = "Missing message log"
      logObj = null
    }
    return [logObj, logMsg]
  }

  return {
    debug: (obj: unknown, msg?: string) => {
      if (process.env.LOG_LEVEL_SERVER === "debug" || process.env.LOG_LEVEL_SERVER === "trace") {
        const [logObj, logMsg] = getLogArgs(obj, msg)

        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify({
            level: "debug",
            message: logMsg,
            pid,
            runtime: "edge",
            ts: new Date().toISOString(),
            ...(logObj ?? null),
            // hostname: require("os").hostname() // not supported in edge runtime
          })
        )
      }
    },
    error: (obj: unknown, msg?: string) => {
      const [logObj, logMsg] = getLogArgs(obj, msg)

      // eslint-disable-next-line no-console
      ;(localLogMethod || console.error)(
        JSON.stringify({
          level: "error",
          message: logMsg,
          pid,
          runtime: "edge",
          ts: new Date().toISOString(),
          ...(logObj ?? null),
          // hostname: require("os").hostname() // not supported in edge runtime
        })
      )
    },
    info: (obj: unknown, msg?: string) => {
      const [logObj, logMsg] = getLogArgs(obj, msg)

      // eslint-disable-next-line no-console
      console.log(
        JSON.stringify({
          level: "info",
          message: logMsg,
          pid,
          runtime: "edge",
          ts: new Date().toISOString(),
          ...(logObj ?? null),
          // hostname: require("os").hostname() // not supported in edge runtime
        })
      )
    },
    warn: (obj: unknown, msg?: string) => {
      const [logObj, logMsg] = getLogArgs(obj, msg)

      // eslint-disable-next-line no-console
      ;(localLogMethod || console.error)(
        JSON.stringify({
          level: "warn",
          message: logMsg,
          pid,
          runtime: "edge",
          ts: new Date().toISOString(),
          ...(logObj ?? null),
          // hostname: require("os").hostname() // not supported in edge runtime
        })
      )
    },
  }
}

type LoggerWrapper = {
  debug: LogFn
  error: LogFn
  info: LogFn
  warn: LogFn
}
type ExposedLogFn = keyof LoggerWrapper

/**
 * Default logger for all server-side code (except NextJS Middleware(s))
 */
function getDefaultLogger(): LoggerWrapper {
  let logger: Logger

  const getLogger = (): Logger => {
    if (!logger) {
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      const pino = require("pino")

      const timestamp = (): string => `,"ts":"${new Date().toISOString()}"`

      const formatters = {
        level(label: string) {
          return { level: label }
        },
      }

      const options: LoggerOptions = {
        formatters,
        level: process.env.LOG_LEVEL_SERVER || "info",
        messageKey: "message",
        redact: [
          "authorization",
          "[*].authorization",
          "cookie",
          "[*].cookie",
          "jobteaser_session",
          "[*].jobteaser_session",
          "password",
          "[*].password",
          "secret",
          "[*].secret",
          "token",
          "[*].token",
          "session",
          "[*].session",
        ],
        timestamp,
      }

      logger = pino(options)
    }

    return logger
  }

  const logFn =
    (fnName: ExposedLogFn): LogFn =>
    (obj: unknown, msg?: string): void => {
      getLogger()[fnName](obj, msg)
    }

  return {
    debug: logFn("debug"),
    error: logFn("error"),
    info: logFn("info"),
    warn: logFn("warn"),
  }
}

function getEnvLogger(): ClientSideLogger | EdgeRuntimeLogger | LoggerWrapper {
  if (process.env.NODE_ENV === "test") {
    return getDefaultLogger()
  }

  if (process.env.NEXT_RUNTIME === "edge") {
    return getEdgeRuntimeLogger()
  }

  if (typeof window !== "undefined") {
    return getClientSideLogger()
  }

  if (typeof window === "undefined" || process.env.NEXT_RUNTIME === "nodejs") {
    return getDefaultLogger()
  }

  throw Error(
    `Unknown runtime for Logger - process.env.NEXT_RUNTIME=${process.env.NEXT_RUNTIME} and process.env.NODE_ENV=${process.env.NODE_ENV}`
  )
}

export const logger = getEnvLogger()
