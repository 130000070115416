/* eslint-disable global-require */

// //////////////////////////////////////////////////////////////////////////
// THIS FILE SHOULD BE KEPT IN JAVASCRIPT BECAUSE USED IN NEXT.CONFIG.JS FILE
// //////////////////////////////////////////////////////////////////////////

/**
 * An object representing the content of build-infos.json
 * @typedef {Object} BuildInfos
 * @property {string} cdn - the cdn URL for static assets
 * @property {string} commit - the head commit used when building app
 */

/**
 * Return the content of build-infos.json
 * @returns {BuildInfos}
 */
const getBuildInfos = () => {
  const env = process.env.NEXT_PUBLIC_ENV || "local"

  if (env === "local") {
    return {}
  }

  // Note that we can't use variable to store and share (with test) the build-info path
  const buildInfos = require("../../../../build-infos.json")

  return buildInfos
}

/**
 * Return the correct url to be used to get static assets
 * If running in local env, your static assets are probably served from your webserver.
 * If running from feature env, staging or production envs, these assets are served through CDN
 * @returns {string} stringified URL to use as hostname + first section of the path
 */
const getAssetsUrl = () => {
  const env = process.env.NEXT_PUBLIC_ENV || "local"

  if (env === "local") {
    return ""
  }

  const { commit, cdn } = getBuildInfos()
  let domain
  switch (env) {
    case "feature": {
      if (!cdn || !commit) {
        throw new Error("for feature env, need cdn and commit values")
      }
      domain = `${cdn}/${commit}`
      break
    }
    case "staging": {
      if (!cdn) {
        throw new Error("for staging, need a cdn value")
      }
      domain = `${cdn}/staging`
      break
    }
    case "production": {
      if (!cdn) {
        throw new Error("for production, need a cdn value")
      }
      domain = `${cdn}/production`
      break
    }
    default: {
      domain = cdn
    }
  }

  return domain
}

/**
 * Return the correct base url
 * If running in local env, your static assets are probably served from your webserver.
 * If running from feature env, staging or production envs, these assets are served through CDN
 * @returns {string} stringified URL
 */
const getAssetsBaseUrl = () => {
  const env = process.env.NEXT_PUBLIC_ENV || "local"

  let domain
  switch (env) {
    case "local": {
      domain = ""
      break
    }
    default: {
      const { cdn } = getBuildInfos()
      if (!cdn) {
        throw new Error("need a cdn value")
      }
      domain = cdn
    }
  }

  return domain
}

module.exports = {
  getAssetsBaseUrl,
  getAssetsUrl,
  getBuildInfos,
}
