import type { I18nLocale } from "@/modules/i18n/types"

type IntlLocale = Record<I18nLocale, { region: string; language: string }>

// Define I18nLocale object as it could be used by Intl API
const localeObjectFromLegacyLocale: IntlLocale = {
  ca: {
    language: "ca",
    region: "es",
  },
  cs: {
    language: "cs",
    region: "cz",
  },
  da: {
    language: "da",
    region: "dk",
  },
  de: {
    language: "de",
    region: "de",
  },
  en: {
    language: "en",
    region: "us",
  },
  es: {
    language: "es",
    region: "es",
  },
  fi: {
    language: "fi",
    region: "fi",
  },
  fr: {
    language: "fr",
    region: "fr",
  },
  // We will fallback on es for the language here because Chromium has an issue with Galicia (gl) and Intl API
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1185099
  gl: {
    language: "es",
    region: "es",
  },
  it: {
    language: "it",
    region: "it",
  },
  nl: {
    language: "nl",
    region: "nl",
  },
  no: {
    language: "no",
    region: "no",
  },
  pl: {
    language: "pl",
    region: "pl",
  },
  pt: {
    language: "pt",
    region: "pt",
  },
  sv: {
    language: "sv",
    region: "se",
  },
}

export const getLocaleTerritoryCode = (locale: I18nLocale, separator = "-"): string => {
  const { region, language } = localeObjectFromLegacyLocale[locale]
  return `${language}${separator}${region.toUpperCase()}`
}

export const getLocaleRegion = (locale: I18nLocale): string => {
  const { region } = localeObjectFromLegacyLocale[locale]
  return region
}
