/* eslint-disable no-restricted-globals */
import type { FetcherOptions, InputRequest } from "./types"

import { getApiUrl } from "@/modules/api/api"

/**
 * We define a default timeout of 5s
 */
export const DEFAULT_TIMEOUT_IN_MS = 5000

const getRequestUrl = (request: InputRequest, options: FetcherOptions): string => {
  let requestUrl: URL

  if (request instanceof Request) {
    requestUrl = new URL(request.url)
  } else if (typeof request === "string") {
    const { serviceDomain } = options
    if (serviceDomain === "EXTERNAL") {
      requestUrl = new URL(request)
    } else if (serviceDomain === "INTERNAL_NEXT_API") {
      if (typeof window === "undefined") {
        if (!request.startsWith("http")) {
          throw new Error(
            `Forbidden to use serviceDomain 'INTERNAL_NEXT_API' with a relative URL on server-side: "${request}".
              Please provide a full URL (ex: http://...${request}).`
          )
        } else {
          requestUrl = new URL(
            getApiUrl({
              path: request,
              serviceDomain,
            })
          )
        }
      } else {
        requestUrl = new URL(
          getApiUrl({
            path: `${window.location.origin}${request}`,
            serviceDomain,
          })
        )
      }
    } else if (serviceDomain === "MONOLITH_API" || serviceDomain === "CAREER_EXPLORER_API") {
      requestUrl = new URL(
        getApiUrl({
          hostname: options.hostname,
          path: request,
          serviceDomain,
        })
      )
    } else {
      requestUrl = new URL(
        getApiUrl({
          path: request,
          serviceDomain,
        })
      )
    }
  } else {
    requestUrl = request
  }

  const { searchParams } = options

  if (searchParams) {
    for (const [key, value] of searchParams.entries()) {
      requestUrl.searchParams.append(key, value)
    }
  }

  return requestUrl.href
}

/**
 * This is the general fetcher of the project.
 *
 * 👉 'input' 👈
 * - 💡Relative path (`/foo...`) for jobteaser APIs or absolute URL (`https://...`) for external APIs
 *
 * 👉 'options' 👈
 * - ⚠️ IMPORTANT ⚠️ Use `nextRequest` on server-side to allow for tracing (also for authenticated API calls)
 * - 💡 See type `FetcherOptions` for a list of valid options (`serviceDomain`, `requestInit`, ...)
 * - 💡 Use `withDefaultContentType` to prevent usage of `content-type: application/json`
 *
 * @return {Promise<Response>} a promise resolving as a Response object
 */
export const fetcher = async (input: InputRequest, options: FetcherOptions): Promise<Response> => {
  const { requestInit, serviceDomain } = options
  const requestUrl = getRequestUrl(input, options)

  const updatedRequestInit = {
    ...requestInit,
    ...(requestInit?.signal ? {} : { signal: AbortSignal.timeout(DEFAULT_TIMEOUT_IN_MS) }),
  }

  // Client-side
  if (typeof window !== "undefined") {
    if (
      serviceDomain === "EXTERNAL" ||
      serviceDomain === "INTERNAL_NEXT_API" ||
      serviceDomain === "CAREER_EXPLORER_API" ||
      serviceDomain === "LEARNING_API"
    ) {
      return fetch(requestUrl, updatedRequestInit)
    }

    const { internalClientOptions } = await import("./client/internalClientOptions")
    return fetch(requestUrl, await internalClientOptions(updatedRequestInit, options || {}))
  }

  // Server-side
  const { internalServerOptions } = await import("./server/internalServerOptions")
  const { getOrStoreFetchResponse } = await import("./server/getOrStoreFetchResponse")
  const { fetchAndMonitor } = await import("@/modules/monitoring/fetchAndMonitor")
  const internalServerOpts =
    serviceDomain === "EXTERNAL" ? updatedRequestInit : internalServerOptions(updatedRequestInit, options || {})
  return getOrStoreFetchResponse(() => fetchAndMonitor(requestUrl, internalServerOpts || {}, options), options)
}
