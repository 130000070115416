import type { ClientPageConfig } from "@/modules/routing/types"

import routingConfig from "@root/client-routing-config.json"

export const getClientPageType = (): string | undefined => {
  if (typeof window === "undefined") {
    throw new Error("'getClientPageType' should not be used on server-side.")
  }
  const pathToProcess = window.location.pathname

  const pageConfig = (routingConfig as ClientPageConfig[]).find(config =>
    config.paths.some(path => new RegExp(path).test(pathToProcess))
  )

  return pageConfig?.page_type
}
