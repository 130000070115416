import type { EnabledFlag, FlagMetadata } from "@/modules/unleash/types"

export const computeActiveFlagsToTrackJsMetadata = (activeFlags: EnabledFlag[]): FlagMetadata => {
  const computedActiveFlags = {} as FlagMetadata

  activeFlags.forEach(({ name, variant }) => {
    if (name) {
      computedActiveFlags[`flag-${name}`] = "enabled"

      if (variant) {
        computedActiveFlags[`flag-${name}`] = variant
      }
    }
  })

  return computedActiveFlags
}
