"use client"

import type { ErrorComponent } from "@/app/types"

import { useEffect } from "react"

import { TranslationProvider } from "@/modules/i18n/components/TranslationProvider"
import { ErrorWithLazyTitle } from "@/modules/layouts/components/Errors/ErrorWithLazyTitle"
import { logger } from "@/modules/monitoring/logger"
import { AppTrackingPageViewPropsUpdater } from "@/modules/tracking/components/AppTrackingPageViewPropsUpdater"

export const ErrorPage: ErrorComponent = ({ error }) => {
  useEffect(() => {
    // Client log to TrackJS
    logger.error(error)
  }, [error])

  return (
    <AppTrackingPageViewPropsUpdater>
      <TranslationProvider>
        <ErrorWithLazyTitle errorId={error?.digest || ""} />
      </TranslationProvider>
    </AppTrackingPageViewPropsUpdater>
  )
}

export default ErrorPage
