"use client"

import type {
  TFunction,
  TranslationComponentOptionsProp,
  TranslationKey,
  TReactFunction,
} from "@/modules/i18n/components/types"
import type { I18nNamespace } from "@/modules/i18n/types"
import type { ReactNode } from "react"

import { startTransition, useContext, useEffect, useState } from "react"

import { isI18nDebugMode } from "@/modules/featureEnv/utils/client/i18nDebugUtils"
import { useLocale } from "@/modules/locales/useLocale"

import { TranslationContext } from "./TranslationProvider"

type UseTranslationReturn<T extends I18nNamespace> = {
  t: TFunction<T>
  tReact: TReactFunction<T>
}
type UseLazyTranslationReturn<T extends I18nNamespace> = UseTranslationReturn<T> & {
  ready: boolean
}

type I18nKeyNS<Namespace, Namespaces extends I18nNamespace[]> = Extract<Namespace, Namespaces[number]>

export function useTranslation<T extends I18nNamespace>(namespace: T[]): UseTranslationReturn<T>
export function useTranslation<T extends I18nNamespace>(namespace: T[], isLazy: boolean): UseLazyTranslationReturn<T>

export function useTranslation<T extends I18nNamespace>(
  namespaces: T[],
  isLazy = false
): UseTranslationReturn<T> | UseLazyTranslationReturn<T> {
  const phrases = useContext(TranslationContext)

  if (!phrases) {
    throw Error("⚠ The translation context must be instantiated at the top level of the app.")
  }

  const locale = useLocale()
  const [readyLocales, setReadyLocales] = useState<string[]>([])
  const ready = readyLocales.includes(locale) && namespaces.every(ns => phrases.hasNamespace(ns, { locale }))

  useEffect(() => {
    if (isLazy && !ready) {
      Promise.all(namespaces.map(ns => phrases.downloadDictionary(locale, ns))).then(() =>
        startTransition(() => {
          setReadyLocales(readyLocales.concat([locale]))
        })
      )
    }
  }, [isLazy, locale, namespaces, readyLocales, phrases, ready])

  const check = (
    i18nKey: TranslationKey<I18nKeyNS<T, T[]>>,
    options?: TranslationComponentOptionsProp<ReactNode>
  ): boolean => {
    const requestedNamespace = i18nKey.split(".")[0] as T

    if (!namespaces.includes(requestedNamespace)) {
      if (process.env.NODE_ENV === "development") {
        // This should never happen
        console.error(
          `⚠ The translation key "${i18nKey}" is not using the requested namespaces: "${namespaces.join(" / ")}".`
        )
      }

      return false
    }

    if (isLazy && !ready && process.env.NODE_ENV === "development") {
      console.error(
        `⚠ Please wait for the "ready" boolean before calling the "t" function for the translation key "${i18nKey}".`
      )
    } else if (!phrases.has(i18nKey, { ...options, locale }) && process.env.NODE_ENV === "development") {
      console.error(
        `⚠ The translation key "${i18nKey}" for the locale "${locale}" is not loaded. You can either:
  - preload its namespace "${requestedNamespace}" on server side with the function getServerSideTranslations
  - use the isLazy argument of the t function to wait for the download of the translations.`
      )
    }

    return true
  }

  const [isDebugMode, setIsDebugMode] = useState(false)

  // necessary to avoid hydration issues
  useEffect(() => {
    setIsDebugMode(isI18nDebugMode())
  }, [])

  const t: TFunction<I18nKeyNS<T, T[]>> = (
    i18nKey: TranslationKey<I18nKeyNS<T, T[]>>,
    options?: TranslationComponentOptionsProp
  ) => {
    if (!check(i18nKey, options) || isDebugMode) {
      return i18nKey
    }

    return phrases.t(i18nKey, { ...options, locale })
  }

  const tReact: TReactFunction<I18nKeyNS<T, T[]>> = (
    i18nKey: TranslationKey<I18nKeyNS<T, T[]>>,
    options?: TranslationComponentOptionsProp<ReactNode>
  ) => {
    if (!check(i18nKey, options) || isDebugMode) {
      return i18nKey
    }

    return <>{phrases.tReact(i18nKey, { ...options, locale })}</>
  }

  if (isLazy === true) {
    return {
      ready,
      t,
      tReact,
    }
  }

  return { t, tReact }
}
