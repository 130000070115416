import { getCookie } from "@/modules/client/cookies/getCookies"
import { isEnvFeature, isEnvStaging } from "@/modules/env/utils"
import { I18N_DEBUG_COOKIE_NAME } from "@/modules/featureEnv/constants"

export const isI18nDebugMode = (): boolean => {
  if (!(isEnvStaging() || isEnvFeature())) return false

  const i18nDebugCookieValue = getCookie(I18N_DEBUG_COOKIE_NAME)

  return i18nDebugCookieValue === "1"
}
