import type { FunctionComponent, PropsWithChildren } from "react"

import { lazy, Suspense } from "react"

import { Heading } from "@jobteaser/spark/components/Heading"
import { Text } from "@jobteaser/spark/components/Text"

import styles from "./Error.module.css"

const IllustrationError = lazy(
  () => import("@jobteaser/ui-kit/assets/illustrations/jds_illustration_error_horizontal.svg?jsx")
)

type NotFoundProps = PropsWithChildren<{
  title: string
  errorId: string
}>

export const Error: FunctionComponent<NotFoundProps> = ({ children, title, errorId }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.content}>
          <Heading as="h1" variant="title1" weight="bold">
            {title}
          </Heading>
          {children}
        </div>
        <div className={styles.illustration}>
          <Suspense>
            <IllustrationError />
          </Suspense>
        </div>
        <Text variant="caption1" className={styles.errorId}>
          {errorId}
        </Text>
      </div>
    </div>
  )
}
