export const getCookie = (name: string): string => {
  if (typeof window === "undefined") {
    return ""
  }

  if (!name) {
    return ""
  }

  return document.cookie.split(";").reduce((acc, item) => {
    const [, itemVal] = item.trim().split(`${name}=`)

    if (itemVal) {
      return itemVal.trim()
    }

    return acc
  }, "")
}
